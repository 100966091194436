.auth-box-dtmood {
    border-radius: 40px;
}

.border-red {
    border: 1.5px solid $red;
}

.bg-dtmood {
    background-color: $dt_mood;
}

.bg-dtmood-purple {
    background-color: $dt_mood_blue;
}

.bg-dtmood-yellow {
    background-color: #FFC832;
}

.bg-dtmood-white {
    background-color: #ffffff;
}

.bg-dtmood-red {
    background-color: $red;
}

.breadcrumb {
    margin-left: 0px;
    padding-left: 0px;
}

.breadcrumb-item {
    + .breadcrumb-item::before {
      content: ">" !important;
      align-self: baseline;
    }
}

.dtmood-icon-dashboard {
    width: 24px;
}

.dtmood-link:link {
    color: #ffffff;
}
  
.dtmood-link:visited {
    color: #ffffff;
}
  
.dtmood-link:hover {
    color: #ffffff;
}
  
.dtmood-link:active {
    color: #ffffff;
}

.emocion-seleccionada {
    background-color: #efeef9;
    color: #381a46;
}

.height-400 {
    height: 400px;
}

.icon-card-dtmood {
    width: 32px;
}

.icon-card-emociones {
    font-size: 48px;
}

.login-button-dtmood {
    width: 100%;
    height: 48px;
    background: #1ECAD3;
    border-radius: 40px;
    border: none;
    color: #FFFFFF;
}

.logo-dtmood {
    width: 140px;
}

.modal-header-white .modal-header button {
    color: #FFFFFF !important;
}

.news-modal {
    .modal-header .close {
        display:none;
    }
}

#partySound {
    display: none;
}

.timeline-card {
    background-color: #efeef9ff;
}

.text-color-dtmood {
    color: #2DCCD3;
}

.text-color-red {
    color: $red;
}

.text-color-white {
    color: #ffffff;
}

.text-color-dark {
    color: $gray-900;
}

.text-color-dark-blue {
    color: #181B45;
}

.text-color-dark-blue-2 {
    color: #0C1523;
}

.text-color-blue {
    color: $dt_mood_blue;
}

.test-li {
    color: $dt_mood_blue;
    list-style-type: none;
    position: relative;
    border-left: 1px solid #656565;
    padding-left: 14px;
    padding-bottom: 1px;
}

.test-li:before {
    content:"·";
    font-size:130px;
    vertical-align: top;
    line-height:20px;
    position: absolute;
    left: -0.1em;
    top: -4px;
    
}

@media (max-width: 400px) {
    .logo-dtmood {
        width: 90px;
    }
}